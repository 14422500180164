<template>
    <div class="w-full vx-row md:w-2/3 lg:w-1/2">
        <vx-card>
        <div class="mt-3 vx-row">
            <header class="w-full vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <div>
                    <h2 >{{ content.title }}</h2>
                    <p>{{ $t("content.edit-content-page.subtitle") }}</p>
                </div>
            </header>
        </div>

        <LabelForm>{{ $t("content.edit-content-page.translated") }}?</LabelForm>
        <vs-select class="border-grey-light xs:w-full sm:w-1/4" v-model="content.translated">
            <vs-select-item :key="option.value" :value="option.value" :text="option.label" v-for="option in this.translatedOptions"/>
        </vs-select>


        <div class="mt-4">
            <editor
                class="mt-4"
                v-model="content.content"
                :api-key="tinyMCEapikey"
                :init="editorConfig"
            />
        </div>

        <div class="mt-10">
            <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="save()">{{ $t("urlform.label.save") }}</vs-button>
            <vs-button color="#242426" type="border" class="w-48 rounded-lg" @click="$router.push(`/resorts/${uuid}/content`)">{{ $t("urlform.label.cancel") }}</vs-button>
        </div>
        </vx-card>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import PagesService from '@/modules/Resorts/Services/PagesService'
import loader from "@/modules/Shared/Mixins/loader.js";
import Editor from '@tinymce/tinymce-vue'
import store from "@/modules/Shared/Store/store";


export default {
    name: 'content-form',
    components: {
        LabelForm,
        'editor': Editor
    },
    mixins: [loader],
    data () {
        return {
            tinyMCEapikey: process.env.VUE_APP_TINY_MCE_API_KEY,
            content: {},
            editorConfig: {
                height: 500,
                menubar: false,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste"
                ],
                toolbar:
                    'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
            },
            translatedOptions: [
                {'label': this.$i18n.t('yes'), 'value': true},
                {'label': this.$i18n.t('no'),  'value': false},
            ]
        }
    },
    async beforeRouteEnter (to, from, next) {
        const content = await PagesService.getPageLang(
            to.params.uuid,
            to.params.page_uuid,
            to.params.lang,
        )
        await store.dispatch('setRouteElement', content)
        next(vm => vm.content = content)
    },
    props: {
        uuid: String,
        page_uuid: String,
        lang: String,
    },
    methods: {
        async save() {
            await this.loadAndNotify(async () => {
                await PagesService.savePageLang(
                    this.$props.uuid,
                    this.$props.page_uuid,
                    this.$props.lang,
                    this.content
                )
            })
        }
    }
}
</script>
