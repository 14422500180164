var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full vx-row md:w-2/3 lg:w-1/2" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "mt-3 vx-row" }, [
            _c(
              "header",
              {
                staticClass:
                  "w-full vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
              },
              [
                _c("div", [
                  _c("h2", [_vm._v(_vm._s(_vm.content.title))]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("content.edit-content-page.subtitle")))
                  ])
                ])
              ]
            )
          ]),
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("content.edit-content-page.translated")) + "?")
          ]),
          _c(
            "vs-select",
            {
              staticClass: "border-grey-light xs:w-full sm:w-1/4",
              model: {
                value: _vm.content.translated,
                callback: function($$v) {
                  _vm.$set(_vm.content, "translated", $$v)
                },
                expression: "content.translated"
              }
            },
            _vm._l(this.translatedOptions, function(option) {
              return _c("vs-select-item", {
                key: option.value,
                attrs: { value: option.value, text: option.label }
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("editor", {
                staticClass: "mt-4",
                attrs: { "api-key": _vm.tinyMCEapikey, init: _vm.editorConfig },
                model: {
                  value: _vm.content.content,
                  callback: function($$v) {
                    _vm.$set(_vm.content, "content", $$v)
                  },
                  expression: "content.content"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled rounded-lg w-48 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("urlform.label.save")))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "w-48 rounded-lg",
                  attrs: { color: "#242426", type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/resorts/" + _vm.uuid + "/content"
                      )
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("urlform.label.cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }